import antimicrobialUnitFragment from "./antimicrobialUnit";

const treatmentDefaultFragment = `
{
    treatment
    {   
        lastReviewed,
        titleDisplay,
        complex,
        dose,
        duration,
        food,
        complex,
        frequency,
        administration,
        antimicrobialUnit->
          ${antimicrobialUnitFragment},
        
      }
}
`;

export default treatmentDefaultFragment;
