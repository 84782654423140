import styled from "styled-components";
import useGetRecommendation from "@hooks/useGetRecommendation";
import mediaQuery from "@utils/mediaQuery";
import Treatments from "./Treatments";
import LineWithText from "./TreatmentDivider";

import { ReactComponent as EditIcon } from "@assets/icons/eidt-button.svg";
import { Button } from "@te-whatu-ora/anatomic";
import { useEffect, useMemo } from "react";
import RichText from "./RichText";
import { formatDate } from "@utils/date";
import {
  getIdApprovalAntimicrobials,
  getLastReviewedDate,
} from "@utils/recommendation";
import Banner from "./Banner";
import { Link } from "react-router-dom";
import TextButton from "./TextButton";
import RecommendationCopy from "./RecommendationCopy";
import ROUTES from "@constants/routes";
import FeedbackForm from "./FeedbackForm";

interface Props {
  id: string;
  onEdit: () => void;
  onNewStart: () => void;
  onRecommendationAvailable: () => void;
  events: string[];
}

const Container = styled.div`
  padding: 80px 16px;
  background: rgba(48, 161, 172, 0.04);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 22px;
  margin: 0 -16px;
  box-sizing: border-box;
  margin-bottom: -88px;

  ${mediaQuery("tablet")`
  margin: 0;
  width: 100vw;
  margin-left: -100px; /* -parentPadding */
  margin-bottom: -88px;
  padding: 80px 16px 80px 16px;
  align-items: center;
  `}

  /* ((maxWidth - 100vw) / 2 - parentPadding) */
  @media screen and (min-width: 1320px) {
    margin-left: calc((1320px - 100vw) / 2 - 100px);
  }
`;

const ContentWrapper = styled.div`
  max-width: 1320px;
`;

const TreatmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${mediaQuery("desktop")`
  flex-direction: row;
  gap: 48px;
`}
`;

const TreatmentWrapper = styled.div`
  padding: 24px 16px;
  background: rgba(194, 223, 227, 0.6);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 22px;
  margin: 0 -16px;
  margin-top: 24px;

  ${mediaQuery("tablet")`
  margin: 0;
  margin-top: 24px;
  border-radius: 12px;
  `}
`;

const TreatmentLengthText = styled.div`
  width: 70%;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
  margin-bottom: 20px;

  ${mediaQuery("tablet")`
  font-size: 30px;
  line-height:36px;
  width: 100%;
  `}
`;

const ConditionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;

  ${mediaQuery("tablet")`
  width: 100%;
  font-size: 24px;
  line-height:28.8px;
  `}

  ${mediaQuery("desktop")`
  width: 66%;
  `}
`;

const EventsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

  ${mediaQuery("tablet")`
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 24px;
  `}
`;

const EventsText = styled.div`
  flex: 2;
  color: #505e79;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  margin-bottom: 8px;

  ${mediaQuery("tablet")`
  margin-bottom: 0;
  `}
`;

const ConditionText = styled.div`
  display: inline-flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

const PrescriptionSection = styled.div`
  flex: 2.5;
  align-items: center;
  flex-direction: column;
`;

export const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-radius: 12px;
  background: #fff;
  box-sizing: border-box;
  padding: 16px;
`;

const ButtonWrapper = styled.div`
  margin-top: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

const EditButton = styled.button`
  display: flex;
  align-items: center;

  color: #00558c;

  span {
    display: flex;
  }

  font-size: 18px;
  font-weight: 700;
  line-height: 28.8px;
  text-decoration-line: underline;
  gap: 16px;
  cursor: pointer;
`;

const LastReviewedAt = styled.div`
  flex: 1;
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

const ConditionContainer = styled.div`
  display: flex;
  gap: 48px;
  flex-direction: column;

  ${mediaQuery("tablet")`
  flex-direction: row;
  `}
`;

const Recommendation = ({
  id,
  onEdit,
  onNewStart,
  onRecommendationAvailable,
  events,
}: Props) => {
  const { recommendation, errorMessage } = useGetRecommendation(id);
  useEffect(() => {
    if (recommendation || errorMessage) {
      onRecommendationAvailable();
    }
  }, [recommendation, errorMessage, onRecommendationAvailable]);

  const {
    treatment1,
    treatment2,
    treatmentNotes1,
    treatmentNotes2,
    copy,
    treatmentLength,
    displayTitle,
    condition,
  } = recommendation || {};

  const showSecondTreatment =
    treatment2 && treatment2?.length > 0 && treatment2?.[0] != null;
  const hasTreatments = !!treatment1;

  const recommendationCopy = copy ?? [];
  const conditionCopy = condition?.content ?? [];
  const recommendationContent = [...recommendationCopy, ...conditionCopy];

  const idApprovals =
    recommendation && getIdApprovalAntimicrobials(recommendation);

  const eventBreadcrumb = events.join(" / ");

  const lastReviewedAt = useMemo(() => {
    if (recommendation) {
      return getLastReviewedDate(recommendation);
    }
  }, [recommendation]);

  return (
    <Container>
      {errorMessage && <div>{errorMessage}</div>}
      {recommendation && (
        <ContentWrapper>
          {treatmentLength && (
            <TreatmentLengthText>
              {treatmentLength} day treatment recommended
            </TreatmentLengthText>
          )}
          <ConditionContainer>
            <ConditionWrapper>
              <ConditionText>
                {displayTitle}
                <EditButton onClick={onEdit}>
                  Edit{/**/}
                  <span>
                    <EditIcon />
                  </span>
                </EditButton>
              </ConditionText>
              <EventsContainer>
                <EventsText>{eventBreadcrumb}</EventsText>
                {lastReviewedAt && (
                  <LastReviewedAt>
                    Last reviewed{" "}
                    {formatDate(new Date(lastReviewedAt), "DAY_MONTH_YEAR")}
                  </LastReviewedAt>
                )}
              </EventsContainer>
            </ConditionWrapper>
          </ConditionContainer>
          {hasTreatments ? (
            <TreatmentContainer>
              <PrescriptionSection>
                {idApprovals && idApprovals?.length > 0 && (
                  <Banner>
                    Seek ID/micro approval before prescribing{" "}
                    {idApprovals.join(", ")}.{" "}
                    <Link
                      to={ROUTES.expert.to}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Find an expert
                    </Link>
                  </Banner>
                )}
                <TreatmentWrapper>
                  <Treatments treatments={treatment1} />
                </TreatmentWrapper>
                {treatmentNotes1 && <RichText copy={treatmentNotes1} />}
                {showSecondTreatment && (
                  <>
                    <LineWithText />
                    <TreatmentWrapper>
                      <Treatments treatments={treatment2} />
                    </TreatmentWrapper>
                    {treatmentNotes2 && <RichText copy={treatmentNotes2} />}
                  </>
                )}
                <ButtonWrapper>
                  <Button onClick={onNewStart}>Start new search</Button>
                  <FeedbackForm
                    treatment={displayTitle || ""}
                    recommendationName={events.join(" | ")}
                    recommendationID={id}
                    trigger={<TextButton>Give feedback</TextButton>}
                  />
                </ButtonWrapper>
              </PrescriptionSection>
              {recommendationContent && recommendationContent.length > 0 && (
                <RecommendationCopy copy={recommendationContent} />
              )}
            </TreatmentContainer>
          ) : (
            <NoteContainer>
              {condition?.content && <RichText copy={condition?.content} />}
            </NoteContainer>
          )}
        </ContentWrapper>
      )}
    </Container>
  );
};

export default Recommendation;
