import { useEffect, useState } from "react";

const useGetStateMachine = (name: string) => {
  const [stateMachine, setStateMachine] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    const importStateMachine = async () => {
      if (!name) {
        setErrorMessage(
          "Please check if the flow name is defined correctly in the GMS (Sanity)."
        );
        setStateMachine(null);
        return;
      }

      try {
        const module = await import(`@stateMachines/${name}`);
        const { machine } = module;

        setStateMachine(machine);
        setErrorMessage("");
      } catch (error) {
        console.error("Error importing the state machine:", error);
        setStateMachine(null);
        setErrorMessage(
          `Please check that the flow name (${name}) from the GMS (Sanity) to ensure it matches exactly.`
        );
      }
    };

    importStateMachine();
  }, [name]);

  return { stateMachine, errorMessage };
};

export default useGetStateMachine;
