const updateFragment = `
{
    _updatedAt,
    title,
    banner,
    copy,
    tags
}
`;

export default updateFragment;
