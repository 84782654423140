import regionQuery from "./region";

const expertQuery = `*[_type == "expert"]
{   
    description,
    email,
    phone,
    title,
    ${regionQuery}
}
`;

export default expertQuery;
