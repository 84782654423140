import styled from "styled-components";
import Category from "./Category";
import { useEffect, useState } from "react";
import mediaQuery from "@utils/mediaQuery";
import useGetBodySystems from "@hooks/useGetBodySystems";

interface Props {
  onClick?: (flow: string) => void;
  onCategoriesAvailable: () => void;
}

const Label = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 24px;
  padding-top: 12px;
`;

const Wrapper = styled.div`
  display: grid;
  gap: 16px;
  justify-content: flex-start;
  grid-template-columns: repeat(2, 163px);

  ${mediaQuery("tablet")`
  grid-template-columns: repeat(3, 187px);
  `}

  ${mediaQuery("wide")`
  grid-template-columns: repeat(5, 187px);
  `}
`;

const CategorySelection = ({ onClick, onCategoriesAvailable }: Props) => {
  const { bodySystems, errorMessage } = useGetBodySystems();

  const [selectedBodySystem, setSelectedBodySystem] = useState("");

  const handleOnClick = (name: string, flow: string) => {
    setSelectedBodySystem(name);
    onClick?.(flow);
  };

  useEffect(() => {
    if (bodySystems || errorMessage) {
      onCategoriesAvailable();
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [bodySystems, errorMessage]);

  return (
    <div>
      <Label>Select a category</Label>
      <Wrapper>
        {errorMessage && <div>{errorMessage}</div>}
        {bodySystems?.map(({ _id, icon, name, flow }) => (
          <Category
            key={_id}
            image={icon}
            name={name}
            flow={flow}
            selectedValue={selectedBodySystem}
            onClick={handleOnClick}
          />
        ))}
      </Wrapper>
    </div>
  );
};
export default CategorySelection;
