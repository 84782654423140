import styled from "styled-components";

const Container = styled.div`
  display: flex;
  padding: 4px 12px;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 22px;
  border-radius: 100px;
  background: #fff9e3;
  color: #000;

  font-size: 14px;
  font-weight: 700;
  line-height: 22.4px;
`;

const Badge = ({ text }: { text: string }) => {
  return <Container>{text}</Container>;
};

export default Badge;
