import { useEffect, useState } from "react";
import {
  STORAGE_KEYS,
  getLocalStorage,
  setLocalStorage,
} from "@utils/localStorage";

const usePersistState = <T>(
  storageKey: (typeof STORAGE_KEYS)[keyof typeof STORAGE_KEYS],
  initialState: T
): [T, (newState: T) => void] => {
  const [internalState, setInternalState] = useState<T>(() => {
    const storageValue = getLocalStorage(storageKey);
    return storageValue ? (JSON.parse(storageValue) as T) : initialState;
  });

  useEffect(() => {
    const storageValue = getLocalStorage(storageKey);
    if (storageValue) {
      setInternalState(JSON.parse(storageValue) as T);
    }
  }, [storageKey]);

  const setState = (newState: T) => {
    setInternalState(newState);
    setLocalStorage(storageKey, JSON.stringify(newState));
  };

  return [internalState, setState];
};

export default usePersistState;
