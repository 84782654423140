import styled from "styled-components";
import { ReactComponent as ChevronIcon } from "@assets/icons/chevron.svg";

interface Props {
  titles: (string | undefined)[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.div`
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
`;

const PageLink = styled.button`
  display: flex;
  align-items: center;
  color: #00558c;
  font-size: 16px;
  font-weight: 700;
  line-height: 25.6px;
  text-decoration-line: underline;
  width: fit-content;

  svg {
    margin-right: 4px;
    display: flex;
  }
`;

const OnThisPage = ({ titles }: Props) => {
  const handleOnClick = (id?: string) =>
    id && document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });

  return (
    <Container>
      <Title>On This Page</Title>
      {titles?.map((title) => (
        <PageLink key={title} onClick={() => handleOnClick(title)}>
          <span>
            <ChevronIcon />
          </span>
          {title}
        </PageLink>
      ))}
    </Container>
  );
};

export default OnThisPage;
