import { ToggleButton } from "@te-whatu-ora/anatomic";

import styled from "styled-components";

interface Props {
  label: string;
  defaultValue?: string;
  labelLeft: string;
  valueLeft: string;
  labelRight: string;
  valueRight: string;
  onChange: (value: string) => void;
}

const Container = styled.div`
  max-width: 720px;
`;

const Label = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 20px;
`;

const ToggleInput = ({
  label,
  defaultValue,
  labelLeft,
  valueLeft,
  labelRight,
  valueRight,
  onChange,
}: Props) => {
  return (
    <Container>
      <Label>{label}</Label>
      <ToggleButton
        defaultValue={defaultValue}
        labelLeft={labelLeft}
        valueLeft={valueLeft}
        labelRight={labelRight}
        valueRight={valueRight}
        onChange={onChange}
      />
    </Container>
  );
};

export default ToggleInput;
