import React, { useMemo } from "react";
import styled from "styled-components";

export interface Row {
  _key: string;
  cells: string[];
}

export interface TableProps {
  rows: Row[];
  description: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  margin-bottom: 16px;
`;

const Table = ({ rows, description }: TableProps) => {
  const headers = rows[0].cells;
  const bodyItems = rows.slice(1);

  const renderHeader = useMemo(() => {
    return headers.map((header) => {
      return <th key={header}>{header}</th>;
    });
  }, [headers]);

  const renderTabeBody = useMemo(() => {
    return bodyItems.map((item) => (
      <tr key={`${item._key}`}>
        {item.cells.map((cell, i) => (
          <td key={`${cell}__${i}`}>{cell}</td>
        ))}
      </tr>
    ));
  }, [bodyItems]);

  return (
    <Container>
      <table>
        <thead>
          <tr>{renderHeader}</tr>
        </thead>
        <tbody>{renderTabeBody}</tbody>
      </table>
      {description && <Description>{description}</Description>}
    </Container>
  );
};

export default Table;
