import { PortableTextBlock } from "@portabletext/react";
import mediaQuery from "@utils/mediaQuery";
import styled from "styled-components";
import RichText from "./RichText";

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 24px;
  flex-direction: column;
  align-self: flex-start;
  box-sizing: border-box;

  border-radius: 12px;
  background: rgba(255, 255, 255, 0.4);

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  ${mediaQuery("tablet")`
    width: 100%;

    p {
        font-size: 16px;
        line-height: 25.6px;
      }
  `}
`;

const RecommendationCopy = ({ copy }: { copy: PortableTextBlock[] }) => {
  return (
    <Container>
      <RichText copy={copy} />
    </Container>
  );
};

export default RecommendationCopy;
