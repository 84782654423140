import { PortableText, PortableTextBlock } from "@portabletext/react";
import styled from "styled-components";
import linkIconUrl from "@assets/icons/link.svg";
import { ReactNode } from "react";

interface Props {
  copy: PortableTextBlock[];
  children?: ReactNode;
}

export const Container = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;

  a {
    color: #00558c;
    font-size: 16px;
    font-weight: 700;
    line-height: 25.6px;
    text-decoration-line: underline;
    display: inline-flex;
    align-items: center;

    &::after {
      content: "";
      display: inline-block;
      margin-left: 8px;
      width: 16px;
      height: 16px;
      background-image: url(${linkIconUrl});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  strong {
    font-weight: 700;
  }

  ul {
    padding-left: 24px;
  }
`;

const RichText = ({ copy, children }: Props) => {
  return (
    <Container>
      <PortableText value={copy} />
      {children}
    </Container>
  );
};

export default RichText;
