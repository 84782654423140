import OnThisPage from "@components/OnThisPage";
import SubpageLayout from "@components/SubpageLayout";
import SubPageContent from "@components/subPageContent";
import useGetAbout from "@hooks/useGetAbout";
import { useMemo } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const About = () => {
  const { aboutData, error } = useGetAbout();

  const titles = aboutData?.map((about) => about.title);

  const renderContents = useMemo(
    () =>
      aboutData?.map((about, i) => (
        <SubPageContent
          key={`${about.title}__${i}`}
          title={about.title || ""}
          bodyContent={about.copy}
          table={about.table}
        />
      )),
    [aboutData]
  );

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <SubpageLayout title="About">
      <Container>
        {titles && <OnThisPage titles={titles} />}
        {renderContents}
      </Container>
    </SubpageLayout>
  );
};

export default About;
