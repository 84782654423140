import mediaQuery from "@utils/mediaQuery";
import styled from "styled-components";

interface Props {
  title: string;
  content: string;
  buttonText?: string;
  buttonOnClick?: () => void;
}

const Container = styled.div`
  display: flex;
  width: calc(100% - 80px);
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  z-index: 5;

  border-radius: 12px;
  background: #fff;

  position: absolute;
  top: 50%;

  ${mediaQuery("tablet")`
  top: auto;
  bottom: 40px;
  right: 40px;
  width: 513px;
  `}
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 25.6px;
`;

const Content = styled.div`
  color: #233863;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  margin-bottom: 16px;

  white-space: pre-line;
`;

const Button = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  appearance: none;

  display: flex;
  height: 40px;
  padding: 10px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #0c818f;
  cursor: pointer;

  color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;

  &:hover {
    background-color: #49a1ab;
    border-color: rgba(255, 255, 255, 0);
  }
`;

const Disclaimer = ({ title, content, buttonText, buttonOnClick }: Props) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Content>{content}</Content>
      {buttonText && <Button onClick={buttonOnClick}>Accept</Button>}
    </Container>
  );
};
export default Disclaimer;
