import antimicrobialUnitFragment from "./antimicrobialUnit";

const treatmentObjectFragment = `
{
    titleDisplay,
    complex,
    dose,
    duration,
    food,
    complex,
    frequency,
    administration,
    antimicrobialUnit->
      ${antimicrobialUnitFragment}
  }
`;

export default treatmentObjectFragment;
