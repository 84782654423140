import { assign, createMachine } from "xstate";
import { OnboardingUserData } from "@context/onboardingContext";
import { northDistricts, southDistricts, roles, practiceAreas } from "@constants/onboarding"

interface OnboardingContext {
  link: string;
  userData: OnboardingUserData | null;
}

const onboardingMachine = createMachine(
  {
    context: { link: "", userData: null } as OnboardingContext,
    on: {
      resetContext: {
        target: ".Where do you practice?",
        actions: "resetWorkflow",
      },
    },
    id: "Where do you practice?",
    initial: "Where do you practice?",
    states: {
      "Where do you practice?": {
        on: {
          "North Island": {
            target: "In which district do you practice? (North Island)",
            actions: {
              type: "saveData",
              params: "location",
            },
          },
          "South Island": {
            target: "In which district do you practice? (South Island)",
            actions: {
              type: "saveData",
              params: "location",
            },
          },
          "Overseas": {
            target: "What is your role?",
            actions: {
              type: "saveData",
              params: "location",
            },
          },
        },
      },
      "In which district do you practice? (North Island)": {
        on: Object.fromEntries(
          northDistricts.map((district) => [
            district,
            {
              target: "What is your role?",
              actions: {
                type: "saveData",
                params: "district",
              },
            },
          ])
        ),
      },
      "In which district do you practice? (South Island)": {
        on: Object.fromEntries(
          southDistricts.map((district) => [
            district,
            {
              target: "What is your role?",
              actions: {
                type: "saveData",
                params: "district",
              },
            },
          ])
        ),
      },
      "What is your role?": {
        on: Object.fromEntries(
          roles.map((role) => [
            role,
            {
              target: "What is your practice area?",
              actions: {
                type: "saveData",
                params: "role",
              },
            },
          ])
        ),
      },
      "What is your practice area?": {
        on: Object.fromEntries(
          practiceAreas.map((area) => [
            area,
            {
              target: "end",
              actions: {
                type: "saveData",
                params: "practiceArea",
              },
            },
          ])
        ),
      },
      end: {
        on: {},
        entry: {
          type: "formFinished",
        },
      },
    },
  },
  {
    actions: {
      resetWorkflow: assign((_context) => ({
        link: "",
        userData: null,
      })),
      formFinished: assign((_context) => ({
        link: "finished",
      })),
      saveData: assign(({ context, event }, params) => ({
        userData: {
          ...context.userData,
          [params as string]: event.type,
        } as OnboardingUserData,
      })),
    },
  }
);

export default onboardingMachine;
