import OnThisPage from "@components/OnThisPage";
import SubpageLayout from "@components/SubpageLayout";
import SubPageContent from "@components/subPageContent";
import useGetDosing from "@hooks/useGetDosing";
import { useMemo } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const ComplexDosing = () => {
  const { dosing: dosingBlocks, error } = useGetDosing();

  const titles = dosingBlocks?.map((dosing) => dosing.title);

  const renderContents = useMemo(
    () =>
      dosingBlocks?.map((dosing, i) => (
        <SubPageContent
          key={`${dosing.title}__${i}`}
          title={dosing.title || ""}
          bodyContent={dosing.copy}
          table={dosing.table}
        />
      )),
    [dosingBlocks]
  );

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <SubpageLayout
      title="Complex dosing &
      monitoring protocols"
      subtitle="Find more detailed information on antibiotics for which more complex dosing and monitoring protocols exist."
    >
      <Container>
        {titles && <OnThisPage titles={titles} />}
        {renderContents}
      </Container>
    </SubpageLayout>
  );
};

export default ComplexDosing;
