import { Expert } from "@hooks/useGetExpert";
import mediaQuery from "@utils/mediaQuery";
import styled from "styled-components";

interface Props {
  title: string;
  experts?: Expert[];
}

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 28.8px;
  margin-bottom: 24px;
`;

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: flex-start;
  border-radius: 12px;
  background-color: #fff;

  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;

  strong {
    font-weight: 700;
  }

  ${mediaQuery("tablet")`
  width: 264px;
`}
`;

const CardWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ExpertCards = ({ title, experts }: Props) => {
  return (
    <>
      <Title>{title}</Title>
      <CardWrapper>
        {experts?.map((expert) => (
          <Card key={expert.title}>
            <strong>{expert.title}</strong>
            <span>{expert.description}</span>
            <span>{expert.email}</span>
            <span>{expert.phone}</span>
          </Card>
        ))}
      </CardWrapper>
    </>
  );
};

export default ExpertCards;
