import { format, differenceInDays } from "date-fns";

export const DateFormat = {
  STANDARD: "yyyy-MM-dd",
  DAY_MONTH_YEAR: "d MMMM yyyy",
  FULL: "eeee, MMMM do, yyyy",
};

export const formatDate = (
  date: Date,
  formatOption: keyof typeof DateFormat = "STANDARD"
) => {
  return format(date, DateFormat[formatOption]);
};

export const getDiffInDays = (currentDate: Date, oldDate: Date): number => {
  return differenceInDays(currentDate, oldDate);
};
