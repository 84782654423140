import SubpageLayout from "@components/SubpageLayout";
import Recommendation from "@components/Recommendation";
import styled from "styled-components";
import { useParams } from 'react-router-dom';
import mediaQuery from "@utils/mediaQuery";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 36px;

  ${mediaQuery("tablet")`
  align-self: flex-start;
  flex-wrap: wrap;
  gap: 48px;
  `}
`;

const RecommendationPage = () => {

  const { recommendationId } = useParams();

  return (
    <SubpageLayout
      title="Recommendation"
      subtitle={recommendationId}
    >
      <Container>
        <Recommendation
          id={recommendationId || ""}
          onEdit={() => {}}
          onNewStart={() => {}}
          onRecommendationAvailable={() => {}}
          events={['null']}
        />
      </Container>
    </SubpageLayout>
  );
};

export default RecommendationPage;
