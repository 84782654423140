import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "@te-whatu-ora/anatomic/styles";
import "./index.css";

import { ThemeProvider } from "@te-whatu-ora/anatomic";
import { webSelfService } from "@te-whatu-ora/anatomic-themes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={webSelfService.className}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
