import { Patient, Practice } from "@constants/antibiotics";
import usePersistState from "@hooks/usePersistState";
import { STORAGE_KEYS } from "@utils/localStorage";
import { ReactNode, createContext, useCallback, useMemo } from "react";

export type PracticeType = (typeof Practice)[keyof typeof Practice];

export type PatientType = (typeof Patient)[keyof typeof Patient];

export interface PracticePatient {
  practice: PracticeType;
  patient: PatientType;
}

interface AntibioticContextValue {
  type: PracticePatient;
  bodySystem?: string;
  handleChangeType?: (practice: PracticeType, patient: PatientType) => void;
}

export const AntibioticContext = createContext<AntibioticContextValue>({
  type: {
    practice: "Primary",
    patient: "Adult",
  },
});

const AntibioticProvider = ({ children }: { children: ReactNode }) => {
  const [practicePatient, setPracticePatient] =
    usePersistState<PracticePatient>(STORAGE_KEYS.PRACTICE_PATIENT, {
      practice: "Primary",
      patient: "Adult",
    });

  const handleChangeType = useCallback(
    (practice: PracticeType, patient: PatientType) => {
      setPracticePatient({
        practice,
        patient,
      });
    },
    [setPracticePatient]
  );

  const value = useMemo(
    () => ({
      type: practicePatient,
      bodySystem: "",
      handleChangeType,
    }),
    [practicePatient, handleChangeType]
  );

  return (
    <AntibioticContext.Provider value={value}>
      {children}
    </AntibioticContext.Provider>
  );
};

export default AntibioticProvider;
