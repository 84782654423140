import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
`;

const Line = styled.div`
  height: 1px;
  background-color: #49a1ab; /* Adjust the color to match the image */
  flex-grow: 1;
`;

const Text = styled.span`
  color: #505e79;
  padding: 0 10px;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
`;

const LineWithText = () => (
  <Container>
    <Line />
    <Text>then switch to</Text>
    <Line />
  </Container>
);

export default LineWithText;
