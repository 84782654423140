import mediaQuery from "@utils/mediaQuery";
import { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children?: ReactNode;
  onClick?: () => void;
}

const Container = styled.button`
  font-size: 16px;
  font-weight: 700;
  line-height: 25.6px;
  text-decoration-line: underline;
  color: #00558c;

  &:hover {
    text-decoration-line: none;
  }

  ${mediaQuery("tablet")`
    font-size: 18px;
    line-height: 28.8px;
`}
`;

const TextButton = ({ children, onClick }: Props) => {
  return <Container onClick={onClick}>{children}</Container>;
};

export default TextButton;
