const ROUTES = {
  home: { name: "home", to: "/" },
  about: { name: "About", to: "/about" },
  guidelines: { name: "guidelines", to: "/guidelines" },
  recommendationPage: { name: "Recommendation Page", to: "/recommendation/:recommendationId" },
  update: { name: "Latest updates", to: "/latest-updates" },
  dosing: { name: "Complex dosing", to: "/complex-dosing" },
  expert: { name: "ID/Micro Experts", to: "/expert" },
};

export default ROUTES;
