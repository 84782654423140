import {
  Recommendation,
  Treatment,
  TreatmentObject,
} from "@hooks/useGetRecommendation";
import { parseISO } from "date-fns";
import { compact, forEach, get, has, isObject, max, mergeWith } from "lodash";

export const mapRecommendation = (
  recommendation: Recommendation
): Recommendation => {
  return {
    ...recommendation,
    treatment1: adjustTreatments(
      recommendation?.treatment1,
      recommendation?.treatmentAdjustments1
    ),
    treatment2: adjustTreatments(
      recommendation.treatment2,
      recommendation.treatmentAdjustments2
    ),
  };
};

export const adjustTreatments = (
  defaults?: Treatment[],
  adjustments?: TreatmentObject[]
): Treatment[] | undefined => {
  if (!defaults) {
    return;
  } else if (!adjustments) {
    return defaults;
  }

  return defaults.map((defaultTreatment, index) => {
    const adjustment = adjustments[index];
    const treatmentObject = defaultTreatment.treatment;

    if (!adjustment) {
      return defaultTreatment;
    }

    const adjustedTreatmentObject = { ...treatmentObject };

    mergeWith({}, treatmentObject, adjustment, (a, b) =>
      b === null ? a : undefined
    );

    return {
      treatment: adjustedTreatmentObject,
    };
  });
};

export const getIdApprovalAntimicrobials = (data: Recommendation) => {
  const extractApprovedAntimicrobials = (treatments?: Treatment[]) =>
    treatments
      ?.filter(({ treatment }) => treatment.antimicrobialUnit?.approval)
      .map(({ treatment }) => treatment.antimicrobialUnit!.title) ?? [];

  const antimicrobials = [
    ...extractApprovedAntimicrobials(data.treatment1),
    ...extractApprovedAntimicrobials(data.treatment2),
  ];

  return Array.from(new Set(antimicrobials));
};

const _collectNestedValuesByKey = (
  obj: Record<string, any>,
  key: string,
  result: string[] = []
): string[] => {
  if (has(obj, key)) {
    const value = get(obj, key);
    if (typeof value === "string") {
      result.push(value);
    }
  }

  // Iterate over all keys in the object
  forEach(obj, (value) => {
    if (isObject(value) && !Array.isArray(value)) {
      _collectNestedValuesByKey(value, key, result);
    } else if (Array.isArray(value)) {
      // If it's an array, check each element
      value.forEach((item) => {
        if (isObject(item)) {
          _collectNestedValuesByKey(item, key, result);
        }
      });
    }
  });

  return result;
};

const findMostRecentUpdateDate = (
  obj: Record<string, any>,
  key: string = "_updatedAt"
): string | null => {
  const dates = _collectNestedValuesByKey(obj, key);

  return findMostRecentDate(dates);
};

const findMostRecentDate = (dates: string[]): string | null => {
  const dateObjects = dates.map((date) => parseISO(date));

  const mostRecentDate = max(dateObjects);

  return mostRecentDate ? mostRecentDate.toISOString() : null;
};

const findLastReviewedDates = (treatments?: Treatment[]) =>
  treatments?.map((treatment) => treatment.treatment.lastReviewed ?? "") ?? [];

export const getLastReviewedDate = (recommendation: Recommendation): string => {
  const mostRecentUpdatedDate = findMostRecentUpdateDate(recommendation) ?? "";

  const firstTreatmentLastReviewedDates = findLastReviewedDates(
    recommendation.treatment1
  );
  const secondTreatmentLastReviewedDates = findLastReviewedDates(
    recommendation.treatment2
  );

  const lastReviewedDates = [
    ...firstTreatmentLastReviewedDates,
    ...secondTreatmentLastReviewedDates,
  ];

  const compactedLastReviewedDates = compact(lastReviewedDates);

  const mostRecentLastReviewedDate =
    findMostRecentDate(compactedLastReviewedDates) ?? "";

  const overallMostRecentDate = findMostRecentDate([
    mostRecentUpdatedDate,
    mostRecentLastReviewedDate,
  ]);

  return overallMostRecentDate ?? "";
};
