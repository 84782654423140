import SubpageLayout from "@components/SubpageLayout";
import Update from "@components/Update";
import useGetUpdate from "@hooks/useGetUpdate";
import mediaQuery from "@utils/mediaQuery";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${mediaQuery("tablet")`
    gap: 24px;
  `}
`;

export const LatestUpdates = () => {
  const { updates } = useGetUpdate();

  return (
    <SubpageLayout
      title="Latest udpates"
      subtitle="Find details about the latest updates to the antibiotics guidelines"
    >
      <Container>
        {updates?.map(({ _updatedAt, tags, title, copy }) => (
          <Update
            key={`${_updatedAt}__${title}`}
            date={_updatedAt}
            title={title || ""}
            tags={tags}
            copy={copy}
          />
        ))}
      </Container>
    </SubpageLayout>
  );
};

export default LatestUpdates;
