import { useState } from "react";
import styled from "styled-components";
import { Button } from "@te-whatu-ora/anatomic";
import CheckBox from "./CheckBox";

interface CheckboxOption {
  label: string;
  value: string;
}

interface Props {
  options: CheckboxOption[];
  onChange: (selectedValues: string[]) => void;
  title: string;
  description?: string;
  onSubmit?: () => void;
  submitButtonLabel?: string;
  defaultValues?: string[];
  disabled?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormLabel = styled.label<{ marginBottom: number }>`
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
  margin: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  white-space: pre-wrap;
  word-break: break-word;
`;

const Description = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
`;

const GroupContainer = styled.div<{ disabled?: boolean }>`
  margin-bottom: 32px;
  color: ${(props) => (props.disabled ? "#777" : "#000")};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

const CheckboxGroup = ({
  title,
  description,
  options,
  onChange,
  onSubmit,
  submitButtonLabel,
  defaultValues,
  disabled,
}: Props) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(
    defaultValues ?? []
  );

  const handleCheckboxChange = (value: string) => {
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)
      : [...selectedValues, value];

    setSelectedValues(newSelectedValues);
    onChange(newSelectedValues);
  };

  return (
    <Container>
      <FormLabel marginBottom={description ? 8 : 24}>{title}</FormLabel>
      {description && <Description>{description}</Description>}
      <GroupContainer disabled={disabled}>
        {options.map((option) => {
          const isChecked = selectedValues.includes(option.value);
          return (
            <CheckBox
              key={option.value}
              label={option.label}
              value={option.value}
              name={option.value}
              isChecked={isChecked}
              onChange={() => handleCheckboxChange(option.value)}
            />
          );
        })}
      </GroupContainer>
      {submitButtonLabel && (
        <Button onClick={onSubmit}>{submitButtonLabel}</Button>
      )}
    </Container>
  );
};

export default CheckboxGroup;
