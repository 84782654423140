import mediaQuery from "@utils/mediaQuery";
import { useState } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";
import { ReactComponent as MenuIcon } from "@assets/icons/menu.svg";

type LinkType = { to: string; name: string };

interface Props {
  links: LinkType[];
}

export const NavContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavLinks = styled.ul<{ isOpen: boolean }>`
  background-color: #f3f9f9;
  flex-direction: column;
  position: absolute;
  top: 39px;
  left: ${({ isOpen }) => (isOpen ? "-16px" : "120%")};
  width: 100%;
  transition: left 0.3s ease-in-out;
  overflow: hidden;
  list-style: none;
  display: flex;
  gap: 1.5rem;
  padding: 40px 16px;
  height: 100vh;

  ${mediaQuery("desktop")`
  padding: 0;
  margin-left: 16px;
  margin-right: 16px;
  height: auto;
  background-color: transparent;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  top: 0;
  left: 0;
  `}
`;

export const NavLink = styled.li`
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  cursor: pointer;
  padding-left: 16px;

  a {
    color: #000;
    text-decoration: none;
  }

  ${mediaQuery("tablet")`
  padding-left: 100px;
  font-size: 16px;
  line-height: 25.6px;
  `}

  ${mediaQuery("desktop")`
  padding-left: 0;
  font-size: 16px;
  line-height: 25.6px;
  `}
`;

const MenuButton = styled.button`
  padding: 6px;

  ${mediaQuery("desktop")`
  display: none;
  `}
`;

const Navigation = ({ links }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const renderNavItems = (link: LinkType) => {
    const { to, name } = link;

    return (
      <Link to={to} onClick={toggleMenu}>
        {name}
      </Link>
    );
  };

  return (
    <NavContainer>
      <NavLinks isOpen={isOpen}>
        {links.map((link) => (
          <NavLink key={link.name}>{renderNavItems(link)}</NavLink>
        ))}
      </NavLinks>

      <MenuButton onClick={toggleMenu}>
        {isOpen ? (
          <span>
            <CloseIcon />
          </span>
        ) : (
          <span>
            <MenuIcon />
          </span>
        )}
      </MenuButton>
    </NavContainer>
  );
};

export default Navigation;
