const handleDelayScroll = (
  ref: React.RefObject<HTMLDivElement>,
  delay: number = 100
) => {
  setTimeout(() => {
    if (ref?.current) ref.current?.scrollIntoView({ behavior: "smooth" });
  }, delay);
};

export default handleDelayScroll;
