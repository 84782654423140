import { PortableTextBlock } from "@portabletext/react";
import mediaQuery from "@utils/mediaQuery";
import styled from "styled-components";
import RichText from "./RichText";
import { formatDate } from "@utils/date";

interface Props {
  date: string;
  title: string;
  tags?: string[];
  copy?: PortableTextBlock[];
}

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  box-sizing: border-box;

  border-radius: 4px;
  background: #fff;

  ${mediaQuery("tablet")`
    width: 769px;
    padding: 32px;
    gap: 8px;
`}
`;

const DateFiled = styled.div`
  color: #505e79;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: wrap;

  ${mediaQuery("tablet")`
  gap: 16px;
  flex-direction: row;;
`}
`;

const Title = styled.div`
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 28.8px;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  display: flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #eef4fa;

  color: #00558c;
  font-size: 12px;
  font-weight: 700;
  line-height: 19.2px;

  ${mediaQuery("tablet")`
    height: 26px;
    padding: 0px 16px;
`}
`;

const Update = ({ date, title, tags, copy }: Props) => {
  const updatedDate = formatDate(new Date(date), "DAY_MONTH_YEAR");
  return (
    <Container>
      <DateFiled>{updatedDate}</DateFiled>
      <TitleContainer>
        <Title>{title}</Title>
        <TagContainer>
          {tags?.map((tag) => <Tag key={tag}>{tag}</Tag>)}
        </TagContainer>
      </TitleContainer>
      {copy && <RichText copy={copy} />}
    </Container>
  );
};

export default Update;
