import { TableProps } from "@components/Table";
import dosingBlockQuery from "@groq/queries/dosingBlock";
import { PortableTextBlock } from "@portabletext/react";
import { client } from "@service/sanity";
import { useEffect, useState } from "react";

interface Dosing {
  title?: string;
  copy?: PortableTextBlock[];
  table?: TableProps;
}

const useGetDosing = () => {
  const [dosing, setDosing] = useState<Dosing[]>();
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchSanity = async () => {
      try {
        const data = await client.fetch(dosingBlockQuery);
        setDosing(data);
      } catch (e) {
        setError(
          "Failed to fetch experts from Sanity. Please refresh the page and try again later."
        );
      }
    };

    fetchSanity();
  }, []);

  return { dosing, error };
};

export default useGetDosing;
