import expertQuery from "@groq/queries/expert";
import { client } from "@service/sanity";
import groupByRegion from "@utils/expert";
import { useEffect, useState } from "react";

type Place = "north" | "south";

interface Region {
  copy: string;
  place: Place;
  title: string;
}

export interface Expert {
  description?: string;
  email?: string;
  phone?: string;
  title?: string;
  region?: Region;
}

export interface GroupedExpert {
  north: {
    [title: string]: Expert[];
  };

  south: {
    [title: string]: Expert[];
  };
}

const useGetExpert = () => {
  const [experts, setExperts] = useState<GroupedExpert | undefined>();
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchSanity = async () => {
      try {
        const data = await client.fetch(expertQuery);
        const groupedExperts = groupByRegion(data);
        setExperts(groupedExperts);
      } catch (e) {
        setError(
          "Failed to fetch experts from Sanity. Please refresh the page and try again later."
        );
      }
    };

    fetchSanity();
  }, []);

  return { experts, error };
};

export default useGetExpert;
