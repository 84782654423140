import conditionQuery from "./condition";
import treatment1Query from "./treatment1";
import treatment2Query from "./treatment2";
import treatmentAdjustmentQuery1 from "./treatmentAdjustment1";
import treatmentAdjustmentQuery2 from "./treatmentAdjustment2";

const recommendationQuery = (
  id: string
) => `*[_type == "recommendation" && _id == "${id}"
    ]{
      displayTitle,
      _updatedAt,
      treatmentLength,
      treatment2Length,
      copy,
      isAdult,
      isPrimary,
      released,
      ${conditionQuery},
      ${treatment1Query},
      ${treatment2Query},
      ${treatmentAdjustmentQuery1},
      ${treatmentAdjustmentQuery2},
      treatmentNotes1,
      treatmentNotes2,
      }`;

export default recommendationQuery;
