import { ReactNode, createContext, useMemo, useCallback } from "react";
import { STORAGE_KEYS } from "@utils/localStorage";

import usePersistState from "@hooks/usePersistState";

export interface OnboardingUserData {
  location: string;
  district: string;
  role: string;
  practiceArea: string;
}

interface OnboardingContextValue {
  userData: OnboardingUserData;
  updateOnboardingData: (data: OnboardingUserData) => void;
  clearOnboardingData: () => void;
  hasSeenOnboarding: boolean;
}

export const OnboardingContext = createContext<
  OnboardingContextValue | undefined
>(undefined);

const INITIAL_USER_DATA = {
  location: "",
  district: "",
  role: "",
  practiceArea: "",
};

const OnboardingProvider = ({ children }: { children: ReactNode }) => {
  const [userData, setUserData] = usePersistState<OnboardingUserData>(
    STORAGE_KEYS.ONBOARDING_DATA,
    INITIAL_USER_DATA
  );

  const [hasSeenOnboarding, setHasSeenOnboarding] = usePersistState<boolean>(
    STORAGE_KEYS.HAS_SEEN_ONBOARDING,
    false
  );

  const handleSetUserData = useCallback(
    (userData: OnboardingUserData) => {
      setUserData(userData);
    },
    [setUserData]
  );

  const updateOnboardingData = useCallback(
    (userData: OnboardingUserData) => {
      handleSetUserData(userData);
      setHasSeenOnboarding(true);
    },
    [handleSetUserData, setHasSeenOnboarding]
  );

  const clearOnboardingData = useCallback(() => {
    setUserData(INITIAL_USER_DATA);
    setHasSeenOnboarding(false);
  }, [setUserData, setHasSeenOnboarding]);

  const contextValue = useMemo(
    () => ({
      userData,
      updateOnboardingData,
      clearOnboardingData,
      hasSeenOnboarding,
    }),
    [userData, hasSeenOnboarding, clearOnboardingData, updateOnboardingData]
  );

  return (
    <OnboardingContext.Provider value={contextValue}>
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingProvider;
