import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as LogoDesktop } from "@assets/icons/te-whatu-ora-logo-big.svg";
import { ReactComponent as LogoMobile } from "@assets/icons/te-whatu-ora-logo-small.svg";
import { ReactComponent as SettingsIcon } from "@assets/icons/settings-icon.svg";

import Navigation from "./Navigation";
import { Link } from "react-router-dom";
import mediaQuery from "@utils/mediaQuery";
import EditProfile from "./EditProfile";
import useClickOutside from "@hooks/useHandleClickOutside";
import { OnboardingContext } from "@context/onboardingContext";
import ROUTES from "@constants/routes";

const Container = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  margin: 0 auto;
  box-sizing: border-box;

  ${mediaQuery("tablet")`
  padding: 16px 100px;
  max-width: 1320px;
  `}
`;

const SettingsButton = styled.button`
  display: none;

  ${mediaQuery("desktop")`

  display: flex;
  padding: 12px 16px;
  height: 50px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #c3d4e1;
`}
`;

const DesktopLogo = styled(LogoDesktop)`
  cursor: pointer;
  display: none;
  ${mediaQuery("tablet")`
  display: block;
`}
`;

const MobileLogo = styled(LogoMobile)`
  cursor: pointer;
  ${mediaQuery("tablet")`
  display: none;
`}
`;

const NavContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MobileSettingsButton = styled.button`
  padding: 6px;

  svg {
    path {
      fill: #0c818f;
    }
  }

  ${mediaQuery("tablet")`
  margin-right: 20px;
`}

  cursor: pointer;
  ${mediaQuery("desktop")`
    display: none;
`}
`;

const ProfileSection = styled.div`
  position: relative;
`;

const ProfileWrapper = styled.div`
  position: absolute;
  top: 70px;
  right: -8px;

  ${mediaQuery("tablet")`
  top: 30px;
  right: 10px;
`}

  ${mediaQuery("desktop")`
  top: 70px;
  right: 10px;
`}
`;

const Header = () => {
  const [showProfile, setShowProfile] = useState(false);
  const [showMobileProfile, setShowMobileProfile] = useState(false);
  const profileRef = useRef<HTMLDivElement>(null);
  const profileMobileRef = useRef<HTMLDivElement>(null);

  const { hasSeenOnboarding } = useContext(OnboardingContext) || {};

  useClickOutside(profileRef, () => setShowProfile(false));
  useClickOutside(profileMobileRef, () => setShowMobileProfile(false));

  return (
    <Container>
      <div>
        <Link to={ROUTES.guidelines.to}>
          <MobileLogo />
          <DesktopLogo />
        </Link>
      </div>

      <NavContainer>
        {hasSeenOnboarding && (
          <div ref={profileMobileRef}>
            <MobileSettingsButton
              onClick={() => {
                setShowMobileProfile((prev) => {
                  return !prev;
                });
              }}
            >
              <SettingsIcon />
            </MobileSettingsButton>
            <ProfileWrapper>
              {showMobileProfile && (
                <EditProfile onEdit={() => setShowMobileProfile(false)} />
              )}
            </ProfileWrapper>
          </div>
        )}

        <Navigation
          links={[ROUTES.update, ROUTES.expert, ROUTES.dosing, ROUTES.about]}
        />

        {hasSeenOnboarding && (
          <ProfileSection ref={profileRef}>
            <SettingsButton onClick={() => setShowProfile((prev) => !prev)}>
              <span>
                <SettingsIcon />
              </span>
              {/* */}
              Settings
            </SettingsButton>
            <ProfileWrapper>
              {showProfile && (
                <EditProfile onEdit={() => setShowProfile(false)} />
              )}
            </ProfileWrapper>
          </ProfileSection>
        )}
      </NavContainer>
    </Container>
  );
};

export default Header;
