import { useEffect, useState } from "react";
import { client } from "@service/sanity";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";

interface BodySystem {
  _id: string;
  name: string;
  flow: string;
  icon?: SanityImageSource;
}

interface Error {
  message: string;
}

const useGetBodySystem = () => {
  const [bodySystems, setBodySystems] = useState<BodySystem[] | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchSanity = async () => {
      try {
        const data = await client.fetch(`*[_type == "bodysystem"]`);
        setBodySystems(data);
      } catch (e) {
        const error = e as Error;
        setErrorMessage(
          `Failed to load body systems from Sanity, Please refresh the page and try again later.
          error: ${error.message}
          `
        );
      }
    };

    fetchSanity();
  }, []);

  return { bodySystems, errorMessage };
};

export default useGetBodySystem;
