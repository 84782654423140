import { useEffect, useState } from "react";
import { client } from "@service/sanity";
import recommendationQuery from "@groq/queries/recommendation";
import { mapRecommendation } from "@utils/recommendation";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { PortableTextBlock } from "@portabletext/types";

interface Antimicrobial {
  nzfLink?: string;
  title: string;
  _updatedAt: string;
}

interface Condition {
  condition?: string;
  name?: string;
  content: PortableTextBlock[];
  snomedcodes?: string[];
}

interface DoseUnit {
  shorthand: string;
  title: string;
  _updatedAt: string;
}

interface Form {
  route: string;
  title: string;
  icon?: SanityImageSource;
}
interface AntimicrobialUnit {
  nzfLink: string | null;
  antimicrobial: Antimicrobial;
  approval: boolean;
  doseUnit: DoseUnit;
  form: Form;
  title: string;
  _updatedAt: string;
}
export interface TreatmentObject {
  duration: number | null;
  food: boolean;
  complex: boolean;
  frequency: number | null;
  antimicrobialUnit: AntimicrobialUnit | null;
  titleDisplay: string | null;
  dose: number | null;
  administration: PortableTextBlock[];
  _updatedAt: string;
}

export interface TreatmentDefault extends TreatmentObject {
  lastReviewed: string | null;
}

export interface Treatment {
  treatment: TreatmentDefault;
}

export interface Recommendation {
  displayTitle?: string;
  treatmentAdjustments2?: TreatmentObject[];
  treatmentLength?: number;
  treatment2Length?: number;
  isPrimary: boolean;
  isAdult?: boolean;
  treatmentAdjustments1?: TreatmentObject[];
  _updatedAt: string;
  released?: boolean;
  treatment1?: Treatment[];
  treatment2?: Treatment[];
  treatmentNotes1?: PortableTextBlock[];
  treatmentNotes2?: PortableTextBlock[];
  copy?: PortableTextBlock[];
  condition: Condition;
}

interface Error {
  message: string;
}

const useGetRecommendation = (id: string) => {
  const [recommendation, setRecommendation] = useState<Recommendation | null>(
    null
  );

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchSanity = async () => {
      try {
        const data = await client.fetch(recommendationQuery(id));
        const mappedData = mapRecommendation(data?.[0]);
        setRecommendation(mappedData);
      } catch (e) {
        const error = e as Error;
        setErrorMessage(
          `Failed to load Recommendation from Sanity, Please refresh the page and try again later.
           error: ${error.message}
          `
        );
      }
    };

    fetchSanity();
  }, [id]);

  return { recommendation, errorMessage };
};

export default useGetRecommendation;
