import styled from "styled-components";
import { ReactComponent as WhiteTickIcon } from "@assets/icons/tick-white.svg";
import mediaQuery from "@utils/mediaQuery";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import urlFor from "@utils/sanityImageBuilder";

export interface CategoryProps {
  image?: SanityImageSource;
  flow: string;
  name: string;
}

interface Props extends CategoryProps {
  selectedValue: string;
  onClick: (name: string, flow: string) => void;
}

const Container = styled.button`
  position: relative;
  display: flex;
  width: 163px;
  min-height: 138px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #c3d4e1;
  background-color: #e6f2f4;
  padding: 18px 16px;
  cursor: pointer;

  &: hover {
    border: 1px solid #018786;
  }

  ${mediaQuery("tablet")`
  width: 187px;
  `}
`;

const Name = styled.span`
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
`;

const IconContainer = styled.span`
  width: 58px;
  height: 58px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  background: #fff;

  img {
    width: 48px;
    height: 48px;
    object-fit: cover;
  }
`;

const Tick = styled.span<{ isActive?: boolean }>`
  visibility: ${(props) => (props.isActive ? "visual" : "hidden")};
  position: absolute;
  top: 10px;
  right: 10px;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-color: #0c818f;
  background-color: ${(props) => (props.isActive ? "#0c818f" : "inherit")};
`;

const Category = ({ image, name, flow, selectedValue, onClick }: Props) => {
  return (
    <Container onClick={() => onClick(name, flow)}>
      <Tick isActive={selectedValue === name}>
        <WhiteTickIcon width={"0.75rem"} />
      </Tick>
      {image && (
        <IconContainer>
          <img
            src={urlFor(image).width(48).height(48).url()}
            alt={`${name} icon`}
          />
        </IconContainer>
      )}
      <Name>{name}</Name>
    </Container>
  );
};

export default Category;
