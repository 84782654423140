import React from "react";
import styled from "styled-components";

interface RadioGroupProps {
  options: { label: string; value: string }[];
  title: string;
  description?: string;
  selectedValue?: string;
  onChange: (value: string) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormLabel = styled.label<{ marginBottom: number }>`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  margin: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  white-space: pre-wrap;
  word-break: break-word;
`;

const InputContainer = styled.div`
  position: relative;
  margin-bottom: 8px;
  position: relative;
  width: fit-content;
`;

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const Checkmark = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 8px;

  overflow: hidden;
  width: 1.5rem;
  height: 1.5rem;
  border-style: solid;
  border-width: 1px;
  border-radius: 100%;
  border-color: #0c818f;
  flex-shrink: 0;
  transition:
    border-color 0.3s ease-out,
    background-color 0.3s ease-out;

  ${RadioInput}:checked ~ & {
    background-color: #0c818f;
  }

  &:after {
    content: "";
    position: absolute;
    display: none;
  }

  ${RadioInput}:checked ~ &:after {
    display: block;
  }

  &:after {
    content: "";
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #ffffff;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  cursor: pointer;
`;

const Description = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
`;

const RadioGroup: React.FC<RadioGroupProps> = ({
  options,
  title,
  description,
  selectedValue,
  onChange,
}) => {
  return (
    <Container>
      <FormLabel marginBottom={description ? 8 : 24}>{title}</FormLabel>
      {description && <Description>{description}</Description>}
      {options.map(({ label, value }) => (
        <InputContainer role="radiogroup" key={`${value}_${label}`}>
          <Label htmlFor={`${title}_${value}`}>
            <RadioInput
              tabIndex={0}
              type="radio"
              name={value}
              id={`${title}_${value}`}
              value={value}
              checked={selectedValue === value}
              onChange={() => onChange(value)}
            />
            <Checkmark />
            {label}
          </Label>
        </InputContainer>
      ))}
    </Container>
  );
};

export default RadioGroup;
