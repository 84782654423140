import { ReactNode } from "react";
import styled from "styled-components";
import { ReactComponent as AlertIcon } from "@assets/icons/alert.svg";
import { ReactComponent as CloseIcon } from "@assets/icons/cross.svg";
import linkIconUrl from "@assets/icons/link.svg";
import mediaQuery from "@utils/mediaQuery";

interface Props {
  onClose?: () => void;
  children: ReactNode;
}

const Container = styled.div`
  position: relative;
  border-radius: 12px;
  background: #fff9e3;
  display: flex;
  width: 100%;
  box-sizing: border-box;

  padding: 24px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000;

  a {
    color: #00558c;
    font-size: 16px;
    font-weight: 700;
    line-height: 25.6px;
    text-decoration-line: underline;
    display: inline-flex;
    align-items: center;

    &::after {
      content: "";
      display: inline-block;
      margin-left: 8px;
      width: 16px;
      height: 16px;
      background-image: url(${linkIconUrl});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  strong {
    font-weight: 700;
  }

  ul {
    padding-left: 24px;
  }

  div {
    flex-wrap: wrap;
  }
`;

const CloseIconButton = styled.button`
  position: relative;
  margin-left: auto;

  ${mediaQuery("tablet")`
  position: absolute;
  top: 24px;
  right: 24px;
  `}
`;

const Banner = ({ children, onClose }: Props) => {
  return (
    <Container>
      <span>
        <AlertIcon />
      </span>
      <div>{children}</div>
      {onClose && (
        <CloseIconButton onClick={onClose}>
          <CloseIcon />
        </CloseIconButton>
      )}
    </Container>
  );
};

export default Banner;
