import { Breakpoint } from "@te-whatu-ora/anatomic/dist/types/css/breakpoints";
import { CSSObject, CSSProp, css } from "styled-components";

const breakpoints = {
  mobile: 376,
  tablet: 768,
  desktop: 1024,
  wide: 1200,
};

const mediaQuery = (key: Breakpoint) => {
  return (style: CSSObject | CSSProp) => css`
    @media only screen and (min-width: ${breakpoints[key]}px) {
      ${style}
    }
  `;
};

export default mediaQuery;
