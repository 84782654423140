import { Expert, GroupedExpert } from "@hooks/useGetExpert";
import { groupBy, mapValues } from "lodash";

const groupByRegion = (data: Expert[]): GroupedExpert | undefined => {
  const groupedByPlace = groupBy(data, (expert) => expert?.region?.place);

  const groupedData = mapValues(groupedByPlace, (placeGroup) =>
    groupBy(placeGroup, (person) => person?.region?.title)
  );

  return {
    north: groupedData.north || {},
    south: groupedData.south || {},
  };
};

export default groupByRegion;
