import { Treatment } from "@hooks/useGetRecommendation";
import Prescription from "./Treatment";
import { ReactComponent as PlusIcon } from "@assets/icons/plus.svg";
import styled from "styled-components";
import { Fragment } from "react/jsx-runtime";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const Treatments = ({
  treatments,
}: {
  treatments: Treatment[] | undefined;
}) => {
  return (
    <Container>
      {treatments?.map(({ treatment }, i) => (
        <Fragment key={treatment?.titleDisplay}>
          <Prescription treatment={treatment} />
          {i !== treatments.length - 1 && <PlusIcon />}
        </Fragment>
      ))}
    </Container>
  );
};

export default Treatments;
