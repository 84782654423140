import {
  // ClientPerspective,
  createClient,
  type ClientConfig,
} from "@sanity/client";
import { formatDate } from "@utils/date";

const currentDate = formatDate(new Date());

// FIX: Build pipeline isn't pulling SANITY env variables correctly on test.antibiotics.nz so have hard coded for now
const perspective = "previewDrafts";
  // (process.env.REACT_APP_SANITY_PERSPECTIVE as ClientPerspective) ??
  // "published";
// const useCdn = perspective === "published";
const useCdn = false;
const token = process.env.REACT_APP_SANITY_TOKEN ?? "";

const config: ClientConfig = {
  apiVersion: currentDate,
  projectId: process.env.REACT_APP_SANITY_PROJECTID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  token,
  useCdn,
  perspective,
};

console.log('Sanity ClientConfig Variables:', config);

export const client = createClient(config);
