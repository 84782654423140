const pageBlockFragment = `
{   
    title,
    copy,
    table,
    description
}
`;

export default pageBlockFragment;
