import useGetUpdate from "@hooks/useGetUpdate";
import mediaQuery from "@utils/mediaQuery";
import styled from "styled-components";
import Banner from "./Banner";
import { Link } from "react-router-dom";
import { useState } from "react";
import ROUTES from "@constants/routes";

const Container = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  width: 100%;
  box-sizing: border-box;

  ${mediaQuery("tablet")`
    padding: 0 100px;
    max-width: 1320px;
`}
`;

const ReviewChanges = styled(Link)`
  position: relative;

  ${mediaQuery("desktop")`
    position: absolute;
    top: 20px;
    right: 68px;
`}
`;

const UpdateBanner = () => {
  const { updates } = useGetUpdate();
  const bannerItems = updates?.filter(
    (update) => update.title && update.banner === true
  );

  const [showAlert, setShowAlert] = useState(true);

  const firstThreeUpdates = bannerItems?.slice(0, 3);
  const titles = firstThreeUpdates?.map((update) => update.title).join(" , ");

  return (
    <Container>
      {showAlert && bannerItems && bannerItems?.length > 0 && (
        <Banner onClose={() => setShowAlert(false)}>
          <strong>Guidelines have changed based on latest research</strong>
          <div>{titles}</div>
          <ReviewChanges to={ROUTES.update.to}>Review changes</ReviewChanges>
        </Banner>
      )}
    </Container>
  );
};

export default UpdateBanner;
