import mediaQuery from "@utils/mediaQuery";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { ReactComponent as BackIcon } from "@assets/icons/back-icon.svg";
import { ReactComponent as BackgroundCover } from "@assets/images/background.svg";
import { useNavigate } from "react-router-dom";

interface Props {
  title: string;
  subtitle?: string;
  children?: ReactNode;
}

const HeaderContainer = styled.div`
  position: relative;
  background-color: #15284c;
  width: 100%;
  height: 437px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  max-width: 1320px;
  padding: 70px 16px;
  box-sizing: border-box;
  width: 100%;
  z-index: 1;

  ${mediaQuery("tablet")`
  padding: 0 100px;
  `}
`;

const Backbutton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px;
  margin-bottom: 24px;

  cursor: pointer;

  ${mediaQuery("tablet")`
    font-size: 18px;
    line-height: 28.8px
`}

  span {
    display: flex;
  }
`;

const Title = styled.h1`
  color: #fff;
  margin-bottom: 16px;

  ${mediaQuery("tablet")`
    font-size: 42px;
    margin-bottom: 20px;
`}
`;

const Subtitle = styled.p`
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.4px;

  ${mediaQuery("tablet")`
    font-size: 20px;
    line-height: 26px;
`}
`;

const TitleConatiner = styled.div`
  max-width: 475px;
`;

const BackgroundImageContainer = styled.div`
  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    symbol {
      path {
        fill: #1e3352;
      }
    }
  }
`;

const ContentWrapper = styled.div`
  background-color: #f3f9f9;
  display: flex;
  padding: 48px 16px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  ${mediaQuery("tablet")`
  max-width: 1320px;
  display: flex;
  padding: 48px 100px 192px 100px;
  `}
`;

const SubpageLayout = ({ title, subtitle, children }: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <HeaderContainer>
        <BackgroundImageContainer>
          <BackgroundCover />
        </BackgroundImageContainer>
        <Wrapper>
          <Backbutton onClick={() => navigate("/guidelines")}>
            <span>
              <BackIcon />
            </span>
            {/* */}
            Back to guidelines
          </Backbutton>
          <TitleConatiner>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </TitleConatiner>
        </Wrapper>
      </HeaderContainer>
      <ContentWrapper>{children}</ContentWrapper>
    </>
  );
};

export default SubpageLayout;
