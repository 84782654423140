import { TableProps } from "@components/Table";
import aboutBlockQuery from "@groq/queries/aboutBlock";
import { PortableTextBlock } from "@portabletext/types";
import { client } from "@service/sanity";
import { useEffect, useState } from "react";

interface About {
  title?: string;
  copy?: PortableTextBlock[];
  table?: TableProps;
}

const useGetAbout = () => {
  const [aboutData, setAboutData] = useState<About[]>();
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchSainity = async () => {
      try {
        const data = await client.fetch(aboutBlockQuery);
        setAboutData(data);
      } catch (e) {
        setError(
          "Failed to fetch experts from Sanity. Please refresh the page and try again later."
        );
      }
    };

    fetchSainity();
  }, []);

  return { aboutData, error };
};

export default useGetAbout;
