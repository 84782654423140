import { PortableTextBlock } from "@portabletext/react";
import Table, { TableProps } from "./Table";
import styled from "styled-components";
import RichText from "./RichText";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 700;
  line-height: 28.8px;
  margin-bottom: 0;
`;

const BackToTopButton = styled.button`
  color: #00558c;
  font-size: 16px;
  font-weight: 700;
  line-height: 25.6px;
  text-decoration-line: underline;
  pointer: cursor;
  align-self: flex-start;
`;

interface Props {
  title: string;
  bodyContent?: PortableTextBlock[];
  table?: TableProps;
}

const SubPageContent = ({ table, title, bodyContent }: Props) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container id={title}>
      <Title>{title}</Title>
      {bodyContent && <RichText copy={bodyContent} />}
      {table && <Table rows={table?.rows} description={table?.description} />}
      <BackToTopButton onClick={scrollToTop}>Back to top</BackToTopButton>
    </Container>
  );
};

export default SubPageContent;
