import styled from "styled-components";
import SubpageLayout from "@components/SubpageLayout";
import useGetExpert from "@hooks/useGetExpert";
import ExpertsSection from "@components/ExpertSection";
import mediaQuery from "@utils/mediaQuery";
import OnThisPage from "@components/OnThisPage";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 36px;

  ${mediaQuery("tablet")`
  align-self: flex-start;
  flex-wrap: wrap;
  gap: 48px;
  `}
`;

const Expert = () => {
  const { experts, error } = useGetExpert();

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <SubpageLayout
      title="Find an ID expert"
      subtitle="Find a local ID expert in your district"
    >
      <Container>
        <OnThisPage titles={["North Island", "South Island"]} />
        <ExpertsSection title="North Island" experts={experts?.north} />
        <ExpertsSection title="South Island" experts={experts?.south} />
      </Container>
    </SubpageLayout>
  );
};

export default Expert;
