import styled from "styled-components";
import { ReactComponent as EditIcon } from "@assets/icons/eidt-button.svg";
import { useContext, useRef } from "react";
import { OnboardingContext } from "@context/onboardingContext";
import { useNavigate } from "react-router-dom";
import ROUTES from "@constants/routes";

const Container = styled.div`
  display: flex;
  width: 335px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(64, 64, 64, 0.1);
  z-index: 100;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #c3d4e1;
`;

const ProfileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
`;

const Profile = styled.div`
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const EditButton = styled.button`
  color: #00558c;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
  text-decoration-line: underline;
  cursor: pointer;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.div`
  color: #505e79;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
`;

const Value = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px;
`;

const EditProfile = ({ onEdit }: { onEdit: () => void }) => {
  const { userData, clearOnboardingData } = useContext(OnboardingContext) || {};
  const { location, district, role, practiceArea } = userData || {};
  const containerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const handleEdit = () => {
    clearOnboardingData?.();
    navigate(ROUTES.guidelines.to, {
      replace: true,
      state: {
        resetGuidelines: true,
      },
    });
    onEdit();
  };

  return (
    <Container ref={containerRef}>
      <Wrapper>
        <ProfileWrapper>
          <Profile>Profile</Profile>
          <ButtonContainer>
            <EditButton onClick={handleEdit}>Edit</EditButton>
            <EditIcon />
          </ButtonContainer>
        </ProfileWrapper>

        <InfoContainer>
          <div>
            <Label>{district! ? "District" : "Location"}</Label>
            <Value>{district ?? location}</Value>
          </div>
          <div>
            <Label>Role</Label>
            <Value>{role}</Value>
          </div>
          <div>
            <Label>Practice Area</Label>
            <Value>{practiceArea}</Value>
          </div>
        </InfoContainer>
      </Wrapper>
    </Container>
  );
};

export default EditProfile;
