import React from "react";
import Header from "@components/Header";
import styled from "styled-components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "@routes/Home";
import OnboardingProvider from "@context/onboardingContext";
import Guidelines from "@routes/Guidelines";
import ComplexDosing from "@routes/ComplexDosing";
import LatestUpdates from "@routes/LatestUpdates";
import Expert from "@routes/Expert";
import UpdateBanner from "@components/UpdateAlert";
import RecommendationPage from "@routes/RecommendationPage";
import ROUTES from "@constants/routes";
import About from "@routes/About";

const Container = styled.div`
  width: 100%;
  background-color: #f3f9f9;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
`;

const { home, guidelines, recommendationPage, update, dosing, expert, about } = ROUTES;

function App() {
  return (
    <Container>
      <BrowserRouter>
        <OnboardingProvider>
          <Wrapper>
            <Header />
            <UpdateBanner />
            <Routes>
              <Route path={home.to} element={<Home />} />
              <Route path={guidelines.to} element={<Guidelines />} />
              <Route path={update.to} element={<LatestUpdates />} />
              <Route path={dosing.to} element={<ComplexDosing />} />
              <Route path={recommendationPage.to} element={<RecommendationPage />} />
              <Route path={expert.to} element={<Expert />} />
              <Route path={about.to} element={<About />} />
            </Routes>
          </Wrapper>
        </OnboardingProvider>
      </BrowserRouter>
    </Container>
  );
}

export default App;
