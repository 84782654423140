import React from "react";
import styled from "styled-components";
import { ReactComponent as DosagIcon } from "@assets/icons/blood-drop.svg";
import { ReactComponent as FrequencyIcon } from "@assets/icons/calendar.svg";
import { ReactComponent as DurationIcon } from "@assets/icons/schedule.svg";
import { ReactComponent as NeutritionIcon } from "@assets/icons/nutrition.svg";
import { ReactComponent as LinkIcon } from "@assets/icons/link.svg";
import Badge from "./Badge";
import mediaQuery from "@utils/mediaQuery";
import { TreatmentObject } from "@hooks/useGetRecommendation";
import urlFor from "@utils/sanityImageBuilder";
import RichText from "./RichText";
import { NoteContainer } from "./Recommendation";
import { Link } from "react-router-dom";
import ROUTES from "@constants/routes";

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mediaQuery("tablet")`
  flex-direction: row;
  justify-content: space-between;
  `}
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  background-color: #e6f2f4;
  border-radius: 100%;
`;

const Title = styled.a`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-decoration-line: none;
  cursor: pointer;

  ${mediaQuery("tablet")`
  font-size: 22px;
  line-height: 30px;
  `}
`;

const Route = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  margin-bottom: 24px;
`;

const AntimicrobialWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${mediaQuery("tablet")`
   gap: 48px;
  `}
`;

const AntimicrobialUnit = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #d4e9ec;
  margin: 24px 0;
  width: 100%;
`;

interface Props {
  treatment: TreatmentObject;
}

const Treatment = ({ treatment }: Props) => {
  const {
    duration,
    food,
    frequency,
    antimicrobialUnit,
    titleDisplay,
    dose,
    administration,
    complex,
  } = treatment;

  const { form, doseUnit, approval, antimicrobial } = antimicrobialUnit || {};

  const formTitle = form?.title;
  const unit = doseUnit?.shorthand;
  const nzfLink = antimicrobial?.nzfLink ?? "";
  const iconUrl = form?.icon;

  const showDivider = approval || administration;

  return (
    <NoteContainer>
      <TopContent>
        <TitleContainer>
          <IconContainer>
            {iconUrl && (
              <img
                src={urlFor(iconUrl).width(48).height(48).url()}
                alt={`${formTitle} icon`}
              />
            )}
          </IconContainer>
          <TitleWrapper>
            <Title href={nzfLink} target="_blank">
              {titleDisplay}{" "}
              <span>
                <LinkIcon />
              </span>
            </Title>
            <Route>{formTitle}</Route>
          </TitleWrapper>
        </TitleContainer>
        <AntimicrobialWrapper>
          <AntimicrobialUnit>
            <span>
              <DosagIcon />
            </span>
            <span>
              {dose}
              {unit}
            </span>
          </AntimicrobialUnit>
          <AntimicrobialUnit>
            <span>
              <DurationIcon />
            </span>
            <span>{frequency} x daily</span>
          </AntimicrobialUnit>
          <AntimicrobialUnit>
            <span>
              <FrequencyIcon />
            </span>
            <span>for {duration} days</span>
          </AntimicrobialUnit>
          {food && (
            <AntimicrobialUnit>
              <span>
                <NeutritionIcon />
              </span>
              <span>with food</span>
            </AntimicrobialUnit>
          )}
        </AntimicrobialWrapper>
      </TopContent>
      {showDivider && <Divider />}
      {approval && <Badge text="Needs ID approval" />}
      {administration && (
        <RichText copy={administration}>
          {complex && (
            <p>
              We recommend reading our complex dosing protocol{" "}
              <Link
                to={ROUTES.dosing.to}
                target="_blank"
                rel="noopener noreferrer"
              >
                Read protocol
              </Link>
            </p>
          )}
        </RichText>
      )}
    </NoteContainer>
  );
};

export default Treatment;
