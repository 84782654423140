export const STORAGE_KEYS = {
  ONBOARDING_DATA: "onboarding_data",
  HAS_SEEN_ONBOARDING: "has_seen_onboarding",
  DISCAIMER_TIME_STAMP: "discaimer_time_stamp",
  PRACTICE_PATIENT: "practice_patient",
};

type StorageKey = (typeof STORAGE_KEYS)[keyof typeof STORAGE_KEYS];

export const setLocalStorage = (key: StorageKey, value: any) =>
  window.localStorage.setItem(key, value);

export const getLocalStorage = (key: StorageKey) =>
  window.localStorage.getItem(key);

export const deleteLocalStorage = (key: StorageKey) =>
  window.localStorage.removeItem(key);
