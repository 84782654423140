const antimicrobialForm = `
{
    route,
    title,
    icon,
    _updatedAt,
}
`;

export default antimicrobialForm;
