import { useMachine } from "@xstate/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import RadioGroup from "./RadioGroup";
import styled from "styled-components";
import handleDelayScroll from "@utils/handleDelayScroll";
import CheckboxGroup from "./CheckBoxGroup";
import { flatMap } from "lodash";

const Wrapper = styled.div`
  margin-bottom: 100px;
`;

type Option = { value: string; label: string };

interface Question {
  question: string;
  options: Option[];
  selectedValues: string | string[];
  isMultiSelect: boolean;
}

interface Props {
  stateMachine: any;
  onFormFinished?: (context?: any, events?: string[]) => void;
  preFiringEvents?: { type: string; param?: { [key: string]: string } }[];
  onReset?: () => void;
  onFormStart?: () => void;
}

const StatelyForm = ({
  stateMachine,
  onFormFinished,
  preFiringEvents,
  onReset,
  onFormStart,
}: Props) => {
  const [current, send] = useMachine(stateMachine);

  const sendTo = send as any;

  const currentQuestionRef = useRef<HTMLDivElement>(null);
  const context = current.context;
  const [isFormFinished, setIsFormFinished] = useState(!!context.link);
  const [multiSelectedValues, setMultiSelectedValues] = useState<string[]>([]);
  const [isMultiSelectDisabled, setIsMultiSelectDisabled] =
    useState<Map<number, boolean>>();
  const [questions, setQuestions] = useState<Question[]>([]);

  useEffect(() => {
    onFormStart?.();
  }, [onFormStart]);

  useEffect(() => {
    setIsFormFinished(!!context.link);
  }, [context.link]);

  useEffect(() => {
    // reset state machine when compoennt gets unmounted
    return () => {
      send({ type: "resetContext" });
      onReset?.();
    };
  }, [send, onReset]);

  useEffect(() => {
    if (isFormFinished) {
      const flattendEvents = flatMap(
        questions.map((question) => question.selectedValues)
      );
      onFormFinished?.(context, flattendEvents);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isFormFinished, context, onFormFinished]);

  const runPreFiringEvents = useCallback(() => {
    // These events will be fired on the background
    if (preFiringEvents && preFiringEvents?.length > 0) {
      preFiringEvents.forEach((event) => {
        sendTo({ type: event.type, ...event.param });
      });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [sendTo]);

  useEffect(() => {
    runPreFiringEvents();
  }, [runPreFiringEvents]);

  const getOptions = useCallback(
    (currentValue: string) => {
      const events = stateMachine.states[currentValue].on ?? {};
      return Object.keys(events).map((key) => {
        const entry = events?.[key]?.[0];
        const option = entry?.description || key;
        return { label: option, value: key };
      });
    },
    [stateMachine.states]
  );

  const getTitle = useCallback(
    (currentValue: string) => {
      return stateMachine.states[currentValue]?.description || currentValue;
    },
    [stateMachine.states]
  );

  const getDescription = useCallback(
    (currentValue: string) => {
      return stateMachine.states[currentValue]?.tags || "";
    },
    [stateMachine.states]
  );

  const factors: { item: string; label: string }[] = useMemo(
    () => context.factors ?? [],
    [context.factors]
  );

  const options = useMemo(() => {
    return factors?.map((factor: { item: string; label: string }) => ({
      value: factor.item,
      label: factor.label,
    }));
  }, [factors]);

  const handleUpdateQuestion = useCallback(
    (
      currentEvent: string,
      selectedValue: string | string[],
      isMulti: boolean
    ) => {
      if (!currentEvent) {
        return;
      }

      setQuestions((prevQuestions) => [
        ...prevQuestions,
        {
          question: currentEvent,
          options: isMulti ? options : [...getOptions(currentEvent)],
          selectedValues: selectedValue,
          isMultiSelect: isMulti,
        },
      ]);
    },
    [getOptions, options]
  );

  const handleMultiSelectChange = useCallback(
    (selectedValues: string[]) => {
      setMultiSelectedValues([...selectedValues]);
      options.forEach((option) => {
        sendTo({
          type: "setMulti",
          item: option.value,
          selected: selectedValues.includes(option.value),
        });
      });
    },
    [sendTo, options]
  );

  const processQuestion = useCallback(
    (question: Question) => {
      const { isMultiSelect, selectedValues } = question;

      if (isMultiSelect) {
        handleMultiSelectChange(selectedValues as string[]);
        send({ type: "next" });
        return;
      }

      send({ type: selectedValues as string });
    },
    [handleMultiSelectChange, send]
  );

  const handleEditQuestion = useCallback(
    (
      newValue: string | string[],
      position: number,
      isTriggeredFromMultiSelect: boolean
    ) => {
      const currentQuestion = questions[position];

      // Reset the state machine
      send({ type: "resetContext" });

      onReset?.();

      // Run pre-fire events
      runPreFiringEvents();

      const newQuestions = questions.slice(0, position);
      setQuestions(newQuestions);

      // Determine if there are no questions left after the update
      const noQuestionsLeft = newQuestions.length === 0;

      const updateAndSend = () => {
        if (!isTriggeredFromMultiSelect) {
          send({ type: newValue as string });
          handleUpdateQuestion(
            currentQuestion.question,
            newValue,
            isTriggeredFromMultiSelect
          );
        }
      };

      if (!noQuestionsLeft) {
        newQuestions.forEach((question) => processQuestion(question));
      }

      updateAndSend();
    },
    [
      handleUpdateQuestion,
      onReset,
      processQuestion,
      questions,
      runPreFiringEvents,
      send,
    ]
  );

  const handleRadioChange = useCallback(
    (e: string, index: number) => {
      handleEditQuestion(e, index, false);
      handleDelayScroll(currentQuestionRef);
    },
    [handleEditQuestion]
  );

  const handleDisableCheckBox = useCallback(
    (position: number, isDisabled: boolean) => {
      setIsMultiSelectDisabled((prevMap) => {
        const newMap = new Map(prevMap);
        newMap.set(position, isDisabled);
        return newMap;
      });
    },
    []
  );

  const handleEditMultiSelect = useCallback(
    (index: number) => {
      setMultiSelectedValues([]);
      handleDisableCheckBox(index, false);
      handleEditQuestion(multiSelectedValues, index, true);
      handleDelayScroll(currentQuestionRef);
    },
    [
      setMultiSelectedValues,
      handleDisableCheckBox,
      handleEditQuestion,
      multiSelectedValues,
    ]
  );

  const handleSubmitMultiSelect = useCallback(() => {
    send({ type: "next" });
    handleUpdateQuestion(current.value, multiSelectedValues, true);
    handleDisableCheckBox(questions.length, true);
    handleDelayScroll(currentQuestionRef);
  }, [
    send,
    current.value,
    handleUpdateQuestion,
    handleDisableCheckBox,
    questions.length,
    multiSelectedValues,
  ]);

  const renderQuestion = useCallback(
    (question: Question, index: number) => {
      return (
        <Wrapper key={`${question.question}_${index}`}>
          {!question.isMultiSelect ? (
            <RadioGroup
              options={question.options}
              description={getDescription(question.question)}
              title={getTitle(question.question)}
              onChange={(e) => handleRadioChange(e, index)}
              selectedValue={question.selectedValues as string}
            />
          ) : (
            <CheckboxGroup
              options={question.options as any}
              title={getTitle(question.question)}
              description={getDescription(question.question)}
              onChange={handleMultiSelectChange}
              onSubmit={() => handleEditMultiSelect(index)}
              disabled={isMultiSelectDisabled?.get(index)}
              defaultValues={multiSelectedValues}
              submitButtonLabel={isMultiSelectDisabled ? "Edit" : "Next"}
            />
          )}
        </Wrapper>
      );
    },
    [
      getTitle,
      getDescription,
      handleRadioChange,
      handleEditMultiSelect,
      handleMultiSelectChange,
      isMultiSelectDisabled,
      multiSelectedValues,
    ]
  );

  const renderCurrentQuestion = useMemo(
    () =>
      !context.isMultiSelect ? (
        <RadioGroup
          options={getOptions(current.value)}
          title={getTitle(current.value)}
          description={getDescription(current.value)}
          onChange={(e) => {
            send({ type: e });
            handleUpdateQuestion(current.value, e, false);
            handleDelayScroll(currentQuestionRef);
          }}
        />
      ) : (
        <CheckboxGroup
          options={options}
          title={getTitle(current.value)}
          description={getDescription(current.value)}
          onChange={handleMultiSelectChange}
          onSubmit={handleSubmitMultiSelect}
          submitButtonLabel="Next"
        />
      ),
    [
      handleSubmitMultiSelect,
      getTitle,
      getDescription,
      context.isMultiSelect,
      getOptions,
      current,
      options,
      handleMultiSelectChange,
      handleUpdateQuestion,
      send,
    ]
  );

  return (
    <div>
      {questions.map(renderQuestion)}
      <div ref={currentQuestionRef}>
        {!isFormFinished && renderCurrentQuestion}
      </div>
    </div>
  );
};

export default StatelyForm;
