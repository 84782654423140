import { useCallback, useContext, useEffect, useState } from "react";
import AntibioticsForm from "@components/AntibioticsForm";
import OnboardingForm from "@components/OnboardingForm";
import { OnboardingContext } from "@context/onboardingContext";
import AntibioticProvider from "@context/antibioticContext";
import styled from "styled-components";
import mediaQuery from "@utils/mediaQuery";
import { useLocation, useNavigate } from "react-router";

const Container = styled.div`
  padding: 48px 16px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  display: flex;
  margin: 0 auto;
  box-sizing: border-box;

  ${mediaQuery("tablet")`
  max-width: 1320px;
  padding: 48px 100px;
  align-items: flex-start;
  `}
`;

const Subheading = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  margin-bottom: 30px;
  font-style: italic;

  ${mediaQuery("tablet")`
  font-size: 16px;
  `}
`;

const Guidelines = () => {
  const { hasSeenOnboarding } = useContext(OnboardingContext) || {};
  const [hasOnboardingFormFinished, setHasOnboardingFormFinished] =
    useState(hasSeenOnboarding);
  const { state } = useLocation();
  const [resetGuidelines, setResetGuidelines] = useState(
    state?.resetGuidelines
  );

  const navigate = useNavigate();

  const resetLocationSate = useCallback(() => {
    navigate(".", {
      state: {
        resetGuidelines: false,
      },
    });
  }, [navigate]);

  useEffect(() => {
    if (state?.resetGuidelines) {
      setHasOnboardingFormFinished(false);
      resetLocationSate();
    }

    setResetGuidelines(state?.resetGuidelines);
  }, [state?.resetGuidelines, resetLocationSate]);

  return (
    <Container>
      {hasOnboardingFormFinished ? (
        <AntibioticProvider>
          <AntibioticsForm />
        </AntibioticProvider>
      ) : (
        <OnboardingForm
          onFinishForm={() => setHasOnboardingFormFinished(true)}
          isFormReset={resetGuidelines}
        />
      )}
    </Container>
  );
};

export default Guidelines;
