import { Expert } from "@hooks/useGetExpert";
import { useMemo } from "react";
import styled from "styled-components";
import ExpertCards from "./ExpertCards";

const Title = styled.h2`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 32px;
`;

const ExpertsSection = ({
  title,
  experts,
}: {
  title: string;
  experts?: {
    [title: string]: Expert[];
  };
}) => {
  const renderedExperts = useMemo(() => {
    return Object.keys(experts || {}).map((region) => (
      <ExpertCards key={region} title={region} experts={experts?.[region]} />
    ));
  }, [experts]);

  return (
    <div id={title}>
      <Title>{title}</Title>
      {renderedExperts}
    </div>
  );
};

export default ExpertsSection;
