export const northDistricts = [
  "Te Tai Tokerau | Northland ",
  "Waitematā",
  "Te Toka Tumai | Auckland ",
  "Counties Manukau",
  "Waikato",
  "Lakes",
  "Hauora a Toi | Bay of Plenty ",
  "Tairāwhiti | Gisborne",
  "Taranaki",
  "Whanganui",
  "Capital, Coast and Hutt Valley",
  "Te Matau a Māui | Hawke's Bay ",
  "Te Pae Hauora o Ruahine o Tararua | MidCentral",
  "Wairarapa"
] as const;

export const southDistricts = [
  "Nelson Marlborough",
  "Waitaha | Canterbury ",
  "South Canterbury",
  "Te Tai o Poutini | West Coast ",
  "Southern"
] as const;

export const roles = [
  "House Officer",
  "Registrar",
  "Fellow",
  "Consultant (SMO)",
  "General Practitioner (GP)",
  "GP Trainee",
  "Nurse Practitioner",
  "Nurse Prescriber",
  "Nurse",
  "Midwife",
  "Dentist",
  "Pharmacist Prescriber",
  "Pharmacist",
  "Optometrist",
  "Podiatrist",
  "Other",
  "Student"
] as const;

export const practiceAreas = [
  "Primary Care",
  "Aged Residential Care",
  "Urgent Care Medicine",
  "Rural Hospital Medicine",
  "Emergency Medicine",
  "General Medicine",
  "Medical Specialties",
  "General Surgery",
  "Orthopaedics",
  "Surgical Specialties",
  "Intensive Care",
  "Obstetrics & Gynaecology",
  "Anaesthetics",
  "General Paediatrics",
  "Paediatric Medical Specialties",
  "Paediatric Surgery",
  "Neonatal Care",
  "Paediatric Intensive Care",
  "Paediatric Anaesthetics"
] as const;