import React from "react";
import styled from "styled-components";
import { ReactComponent as CheckBoxIcon } from "@assets/icons/check-box.svg";
import mediaQuery from "@utils/mediaQuery";

interface Props {
  value: string;
  label: string;
  name: string;
  isChecked?: boolean;
  onChange?: (selectedValue: string) => void;
}

const InputContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 40px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  width: fit-content;

  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 28.8px;

  ${mediaQuery("desktop")`
  &:hover input:checked ~ span {
    background-color: #15284c;
    border-color: #15284c;
  }

  &:hover input ~ span {
    border-color: #15284c;
  }

  `}

  input:checked ~ span {
    background-color: #0c818f;
  }
`;

const HiddenCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Checkmark = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  border: solid #0c818f;
  border-width: 3px;
  border-radius: 3px;
`;

const CheckBox = ({ label, name, value, isChecked, onChange }: Props) => {
  return (
    <InputContainer>
      {label}
      <HiddenCheckbox
        type="checkbox"
        id={value}
        name={name}
        value={value}
        checked={isChecked}
        onChange={() => onChange?.(value)}
      />
      <Checkmark>{isChecked && <CheckBoxIcon />}</Checkmark>
    </InputContainer>
  );
};

export default CheckBox;
