import updateQuery from "@groq/queries/update";
import { PortableTextBlock } from "@portabletext/react";
import { client } from "@service/sanity";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";

interface Update {
  _updatedAt: string;
  title?: string;
  banner: boolean;
  copy?: PortableTextBlock[];
  tags?: string[];
}

const useGetUpdate = () => {
  const [updates, setUpdates] = useState<Update[]>();
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchSanity = async () => {
      try {
        const data = await client.fetch(updateQuery);
        const updatesOrderByDate = orderBy(data, ["_updatedAt"], ["desc"]);
        setUpdates(updatesOrderByDate);
      } catch (e) {
        setError(
          "Failed to load updates from Sanity, Please refresh the page and try again later."
        );
      }
    };

    fetchSanity();
  }, []);

  return {
    updates,
    error,
  };
};

export default useGetUpdate;
