import antimicrobialFragment from "./antimicrobial";
import antimicrobialForm from "./antimicrobialForm";
import doseUnitFragment from "./doseUnit";

const antimicrobialUnitFragment = `
      {
        antimicrobial->
            ${antimicrobialFragment},
        approval,
        doseUnit->
            ${doseUnitFragment},
        form->
            ${antimicrobialForm},
        title,
        nzfLink,
        _updatedAt,
      }
`;

export default antimicrobialUnitFragment;
