import { Dialog, Button, InputField } from "@te-whatu-ora/anatomic";
import mediaQuery from "@utils/mediaQuery";
import { useState } from "react";
import styled from "styled-components";
import CheckBox from "./CheckBox";
import { FeedbackFormErrors, getValidationError } from "@utils/feedback";
// import { OnboardingContext } from "@context/onboardingContext";

interface FormValue {
  email?: string;
  feedback: string;
  isMaoriPacific: boolean;
}
interface Props {
  trigger:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  treatment: string;
  recommendationID: string;
  recommendationName: string;
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQuery("tablet")`
  width: 530px;
`}
`;

const Title = styled.div`
  color: #15284c;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  max-width: 444px;
  margin-bottom: 24px;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const Label = styled.label<{
  hasError?: boolean;
}>`
  display: flex;
  color: ${({ hasError }) => (!hasError ? "#000" : "#d02323")};
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.6px;
  margin-bottom: 8px;
`;

const CheckBoxWrapper = styled.div`
  align-self: flex-start;
  margin-bottom: 24px;
`;

const ErrorField = styled.div`
  color: #d02323;
  font-size: 12px;
  font-weight: 600;
  line-height: 25.6px;
`;

const FeedbackForm = ({
  trigger,
  treatment,
  recommendationID,
  recommendationName,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  // const { userData } = useContext(OnboardingContext) || {};
  const [formValues, setFormValues] = useState<FormValue>({
    feedback: "",
    isMaoriPacific: false,
  });

  const [formErrors, setFormErrors] = useState<FeedbackFormErrors>({
    email: "",
    feedback: "",
  });

  const [genericErrorMessage, setGenericErrorMessage] = useState("");

  const resetFormValues = () => {
    setFormValues({
      email: "",
      feedback: "",
      isMaoriPacific: false,
    });
  };

  const sendFeedback = async () => {
    // const { feedback, isMaoriPacific, email } = formValues;
    // const { district, role, practiceArea, location } = userData || {};

    setGenericErrorMessage("");
    setIsFormSubmitted(true);
    resetFormValues();

    // try {
    //   await postFeedback({
    //     email,
    //     district: district || "",
    //     role: role || "",
    //     practiceArea: practiceArea || "",
    //     location: location || "",
    //     recommendation: recommendationID,
    //     recommendationName,
    //     feedback,
    //     isMaoriPacific,
    //   });
    // } catch {
    //   setGenericErrorMessage(
    //     "Failed to send a feedback, Please try again later"
    //   );
    // } finally {
    //   setIsFormSubmitted(true);
    //   resetFormValues();
    // }
  };

  const handleOnClose = async () => {
    if (isFormSubmitted) {
      setOpen(false);
      setIsFormSubmitted(false);
    } else {
      const emailError = getValidationError(formValues, "email");
      const feedbackError = getValidationError(formValues, "feedback");
      const hasNoFormError = !emailError && !feedbackError;

      setFormErrors({
        email: emailError,
        feedback: feedbackError,
      });

      if (hasNoFormError) sendFeedback();
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: keyof FormValue
  ) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  return (
    <Dialog.Root
      open={open}
      trigger={trigger}
      onOpenChange={(open: boolean) => {
        setOpen(open);
        if (isFormSubmitted) {
          setIsFormSubmitted(false);
        }
      }}
    >
      <Dialog.Content>
        <ContentWrapper>
          <Title>
            {isFormSubmitted
              ? "Thanks for your feedback!"
              : `Share your feedback about ${treatment} guidelines`}
          </Title>
          {genericErrorMessage && <div>{genericErrorMessage}</div>}
          {!isFormSubmitted && (
            <>
              <InputWrapper>
                <Label hasError={Boolean(formErrors.email)}>
                  Email Address
                </Label>
                <InputField
                  type="email"
                  name="email"
                  onChange={(e) => handleChange(e, "email")}
                  error={Boolean(formErrors.email)}
                />
                {formErrors.email && (
                  <ErrorField>{formErrors.email}</ErrorField>
                )}
              </InputWrapper>
              <InputWrapper>
                <Label hasError={Boolean(formErrors.feedback)}>
                  Your feedback
                </Label>
                <InputField
                  multiline
                  type="text"
                  name="feedback"
                  onChange={(e) => handleChange(e, "feedback")}
                  error={Boolean(formErrors.feedback)}
                />
                {formErrors.feedback && (
                  <ErrorField>{formErrors.feedback}</ErrorField>
                )}
              </InputWrapper>
              <CheckBoxWrapper>
                <CheckBox
                  label="I work for a Māori or Pacific healthcare provider."
                  value="isMaoriPacific"
                  name="isMaoriPacific"
                  isChecked={formValues.isMaoriPacific}
                  onChange={() =>
                    setFormValues((prev) => ({
                      ...prev,
                      isMaoriPacific: !prev.isMaoriPacific,
                    }))
                  }
                />
                (This is to enable us to identify feedback from Māori and Pacific Providers)
              </CheckBoxWrapper>
            </>
          )}

          <Button onClick={handleOnClose}>
            {isFormSubmitted ? "Back to gudelines" : "Send"}
          </Button>
        </ContentWrapper>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default FeedbackForm;
