const condition = `
    {
        condition,
        name,
        content,
        snomedcodes,
        _updatedAt,
    }
`;

export default condition;
